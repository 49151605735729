export const emailTemplates = {
  newEmployee: process.env.NEXT_PUBLIC_newEmployee || "",
  bulkUpload: process.env.NEXT_PUBLIC_bulkUpload || "",
  updateEmployee: "d-ee1219eed15f48d091d7543b339bfcb3",
  inviteEmployee: "d-34bb11cfff3545a0ae10a8979019b21f",
  verifyAllocation: "d-2a687539fb15428cb8e57a88cc7a0810",
  reminderMail: "d-fe6f80d3f6594b338c3b228b09ef5223",
};

export const emailAddress = {
  administratie:
    process.env.NEXT_PUBLIC_BACKOFFICE_EMAIL || "thomas+admin@weareantenna.be",
};


export const LOGIN_URL =
  process.env.NEXT_PUBLIC_LOGIN_URL ||
  "https://sparrow-antenna.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=5kq14hqdk5dh7m8j5ivjqjhvok&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Floggedin";
//"https://sparrow-antenna.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=5frq2b4lrreg3s61s3evc24ncm&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=https%3A%2F%2Fplanning.digitalpayrollservices.be%2Floggedin
//"https://sparrow-antenna.auth.eu-central-1.amazoncognito.com/oauth2/authorize?client_id=5kq14hqdk5dh7m8j5ivjqjhvok&response_type=code&scope=email+openid+phone+aws.cognito.signin.user.admin&redirect_uri=https%3A%2F%2Fqa.sparrow.boemm.eu%2Floggedin"
export const TOKEN_URL =
  process.env.NEXT_PUBLIC_TOKEN_URL ||
  "https://sparrow-antenna.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=5kq14hqdk5dh7m8j5ivjqjhvok&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Floggedin&client_secret=" +
    process.env.COGNITO_SECRET_HASH +
    "&code=";

export const REFRESH_URL =
  process.env.NEXT_PUBLIC_REFRESH_URL ||
  "https://sparrow-antenna.auth.eu-central-1.amazoncognito.com/oauth2/InitiateAuth";

export const routes = {
  employees: "/employees",
  thankyou: "/thankYou",

  employeesRegister: "/employees/register",
  employeesBulk: "/employees/bulk",
  addCompany: "/admin/add",
};
