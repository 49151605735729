import { IAllocation } from "../types/allocation";
import { IEmployee } from "../types/employee";
import { IEvent } from "../types/event";
import { IShift } from "../types/shift";
import dateToLocaleString from "./dateToLocaleString";

export const formatDataForVerifyAllocationBackoffice = (
  employee: IEmployee,
  shift: IShift,
  allocation: IAllocation,
  event: IEvent
) => {
  return {
    subject: "!!! Allocatie bevestigd voor vandaag of morgen",
    employee: `${employee.firstName} ${employee.lastName}`,
    rrnr: employee.registerNumber,
    eventName: event.name,
    eventDate: dateToLocaleString(allocation.date),
    shiftStart: shift.shiftStart,
    shiftEnd: shift.shiftEnd,
    shiftName: shift.shiftName,
    breakStart: shift.breakStart === "" ? "Geen pauze" : shift.breakStart,
    breakEnd: shift.breakEnd === "" ? "Geen pauze" : shift.breakEnd,
    company: allocation.company
  };
};

export const formatDataForRemoveFromAllocation = (
  allocation: IAllocation,
  shift: IShift,
  event: IEvent
) => {
  return {
    subject: "U bent verwijderd van een shift",
    receiverName: allocation.employeeFirstName,
    eventName: event.name,
    eventDate: allocation.date,
    shiftStart: shift.shiftStart,
    shiftEnd: shift.shiftEnd,
    breakStart: shift.breakStart === "" ? "Geen pauze" : shift.breakStart,
    breakEnd: shift.breakEnd === "" ? "Geen pauze" : shift.breakEnd,
  };
};

export const formatDataForRemoveFromAllocationBackOffice = (
  allocation: IAllocation,
  shift: IShift,
  event: IEvent,
  employee: IEmployee,
  username: string
) => {
  return {
    subject: "Medewerker allocatie ongedaan gemaakt",
    receiverName: allocation.employeeFirstName,
    userName: username,
    companyName: allocation.company,
    employee: `${employee.firstName} ${employee.lastName}`,
    rrnr: employee.registerNumber,
    eventName: event.name,
    eventDate: allocation.date,
    shiftStart: shift.shiftStart,
    shiftEnd: shift.shiftEnd,
    breakStart: shift.breakStart === "" ? "Geen pauze" : shift.breakStart,
    breakEnd: shift.breakEnd === "" ? "Geen pauze" : shift.breakEnd,
  };
};

export const formatDataForVerification = (
  employee: IEmployee,
  shift: IShift,
  allocation: IAllocation,
  event: IEvent
) => {
  return {
    subject: "Bevestiging allocatie",
    receiverName: employee.firstName,
    eventName: event.name,
    eventDate: dateToLocaleString(allocation.date),
    shiftName: shift.shiftName,
    shiftStart: shift.shiftStart,
    shiftEnd: shift.shiftEnd,
    breakStart: shift.breakStart === "" ? "Geen pauze" : shift.breakStart,
    breakEnd: shift.breakEnd === "" ? "Geen pauze" : shift.breakEnd,
    shiftInfo: shift.shiftInfo,
  };
};
