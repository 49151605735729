import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import useStore from "../store/store";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const { loadData, dataLoaded } = useStore();
  const router = useRouter();
  useEffect(() => {
    if (document.location.href.includes("localhost")) {
      document.documentElement.style.setProperty("--primary", "#284c16");
    }
  }, []);
  useEffect(() => {
    // call api to get all data
    router.pathname !== "/invites/[invitationHash]" &&
      router.pathname !== "/thankYou" &&
      router.pathname !== "/loggedin" &&
      router.pathname !== "/loggedout" &&
      router.pathname !== "/verify/[linkHash]/available" &&
      router.pathname !== "/verify/[linkHash]/notAvailable" &&
      !dataLoaded &&
      loadData();
    //   router.pathname !== "/invites/[invitationHash]" &&
    //     router.pathname !== "/thankYou" &&
    //     router.pathname !== "/loggedin" &&
    //     router.pathname !== "/loggedout" &&
    //     router.pathname !== "/verify/[linkHash]/available" &&
    //     router.pathname !== "/verify/[linkHash]/notAvailable" &&
    //     !dataLoaded &&
    //     console.log("🐠🐠🐠 setting interval") &&
    //     setInterval(() => {
    //       console.log("load data");
    //       loadData();
    //     }, 5 * 60000); // time interval of 5 minutes
    //
  }, [dataLoaded, router]);
  return <Component {...pageProps} />;
}

export default MyApp;
