import create from "zustand";
import { devtools } from "zustand/middleware";
import { IEmployee } from "../types/employee";
import { IWorkTime } from "../types/worktime";
import { StoreType } from "../types/store";
import { emailAddress, LOGIN_URL, routes } from "../utils/constants";
import router from "next/router";
import dayjs from "dayjs";
import worktimes from "../pages/api/worktimes";
import {
  formatDataForRemoveFromAllocation,
  formatDataForRemoveFromAllocationBackOffice,
} from "../utils/emailFormatters";
import { isTodayOrTomorrow } from "../utils/dates";

const useStore = create(
  devtools<StoreType>(
    (set, get): StoreType => ({
      activeShiftForInfoModal: null,
      setActiveShiftForInfoModal: (shift) =>
        set((state) => ({ ...state, activeShiftForInfoModal: shift })),
      setTimeSheetCheck: (dateId, check) => {
        fetch("/api/dates/timeSheetCheck", {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: dateId, timeSheetCheck: check }),
        });
        set({
          eventDates: get().eventDates.map((date) => {
            if (date._id === dateId) {
              return { ...date, timeSheetCheck: !date.timeSheetCheck };
            }
            return date;
          }),
        });
      },
      duplicateId: "",
      setDuplicateId: (duplicateId) => set({ duplicateId }),
      addDate: async (event, date, duplicate) => {
        const res = await fetch(`/api/dates`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eventDate: date,
            eventId: event,
            duplicate: duplicate ? get().duplicateId : "",
          }),
        });

        const response = await res.json();

        if (response.shifts) {
          set((state) => ({
            eventDates: state.eventDates.concat(response.date),
            shifts: state.shifts.concat(...response.shifts),
            loading: false,
          }));
        } else {
          set((state) => ({
            eventDates: state.eventDates.concat(response.date),
            loading: false,
          }));
        }
      },
      error: "",
      loading: false,
      setLoading: (loading: boolean) => set({ loading }),
      companies: [],
      companiesLoaded: false,
      myCompany: "",
      username: "",
      loadCompanies: async () => {
        try {
          const companiesRespons = await fetch("/api/admin/company");
          const companiess = await companiesRespons.json();
          return set({
            companies: companiess,
            companiesLoaded: true,
          });
        } catch (error) {
          return set({
            companies: [],
          });
        }
      },
      addCompany: async (comp) => {
        try {
          get().setLoading(true);
          const companyRespons = await fetch("/api/admin/company", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              companyName: comp.companyName.trim(),
              companyNumber: comp.companyNumber.trim(),
            }),
          });
          const company = await companyRespons.json();
          console.log(company);
          get().setLoading(false);
          return set({ companies: [...get().companies, company] });
        } catch (error) {
          get().setLoading(false);
          return set({
            companies: [],
          });
        }
      },
      addCompanyUser: async (companyUser) => {
        try {
          get().setLoading(true);
          const companyRespons = await fetch("/api/admin/companyUser", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              company: companyUser.company,
              name: companyUser.email,
              password: companyUser.password,
            }),
          });

          const company = await companyRespons.json();

          if (
            company.statusCode === 400 &&
            company.code === "UsernameExistsException"
          ) {
            console.log("test");
            // @ts-ignore
            document.getElementById("adminError")?.showModal();
          }
          console.log("test", company);
          get().setLoading(false);
        } catch (error: any) {
          get().setLoading(false);
          return set({
            companies: [],
          });
        }
      },
      dataLoaded: false,
      admin: false,
      sendEmail: async (to, templateId, data) => {
        let response;
        try {
          response = await fetch("/api/mail", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              to,
              templateId,
              data,
            }),
          });

          if (response.status === 500 || response.status === 403) {
            throw new Error("Mail not send");
          }
        } catch (e) {
          console.log("error catch opening modal...");
          // @ts-ignore
          document.getElementById("emailSendError")?.showModal();
          return false;
        }
        return !!response;
      },
      deleteEvent: async () => {
        const idToDelete = get().activeEvent?._id;
        if (!idToDelete) return;
        await fetch("/api/events", {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id: idToDelete,
          }),
        });

        const filteredEvents = get().events.filter(
          (ev) => ev._id.toString() !== idToDelete
        );
        set({
          events: filteredEvents,
          activeEvent: filteredEvents?.[0],
        });
      },
      editEvent: async (name, description) => {
        const eventId = get().activeEvent?._id;
        if (!eventId) return;

        fetch("/api/events", {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id: eventId,
            name,
            description,
          }),
        });
        return set({
          events: get().events.map((ev) =>
            ev._id.toString() === eventId ? { ...ev, name, description } : ev
          ),
        });
      },
      loadNewData: async () => {
        console.log("🚀🚀🚀 load data from server");
        try {
          const initialDataResponse = await fetch("/api/newData");

          if (initialDataResponse.status === 403) {
            console.info("🔑 Go to login");
            window.location.href = LOGIN_URL;
            return;
          }

          const initialData = await initialDataResponse.json();

          // setTimeout(() => {
          //   get().loadData();
          // }, 5 * 60 * 1000);
          return set({
            sollicitations: initialData.sollicitations,
            allocations: initialData.allocations,
            worktimes: initialData.worktimes,
          });
        } catch (e) {
          console.error("🔥", e);
        }
      },
      logo: "",
      loadData: async () => {
        console.log("🚀🚀🚀 load data from server");
        try {
          const initialDataResponse = await fetch("/api/initialData");

          if (initialDataResponse.status === 403) {
            console.info("🔑 Go to login", LOGIN_URL);
            window.location.href = LOGIN_URL;
            return;
          }

          const initialData = await initialDataResponse.json();

          // setTimeout(() => {
          //   get().loadData();
          // }, 5 * 60 * 1000);
          return set({
            events: initialData.events,
            activeEvent: initialData.events?.[0],
            eventDates: initialData.dates,
            shifts: initialData.shifts,
            employees: initialData.employees,
            sollicitations: initialData.sollicitations,
            allocations: initialData.allocations,
            worktimes: initialData.worktimes,
            admin: initialData.admin,
            dataLoaded: true,
            myCompany: initialData.company,
            username: initialData.username,
            logo: initialData.logo,
            loading: false,
          });
        } catch (e) {
          console.error("🔥", e);
        }
      },
      saveLogo: async (logo) => {
        try {
          await fetch("/api/companyLogo", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ logo }),
          });

          set({ logo, loading: false });
        } catch (error) {
          set({ loading: false });
        }
      },
      clearData: () => {
        return set({
          events: [],
          activeEvent: null,
          eventDates: [],
          shifts: [],
          employees: [],
          sollicitations: [],
          allocations: [],
          worktimes: [],
          admin: false,
          dataLoaded: false,
          myCompany: "",
          logo: "",
        });
      },
      events: [],
      addEvent: async (name, description) => {
        try {
          const res = await fetch(`/api/events`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              description,
            }),
          });

          const newEvent = await res.json();
          set((state) => ({
            loading: false,
            events: state.events.concat(newEvent),
            activeEvent: newEvent,
            activeShift: null,
          }));
        } catch (error) {
          set({
            loading: false,
            error: "Error bij toevoegen van event.",
          });
        }
      },
      removeEvent: (eventId) =>
        set((state) => ({
          events: state.events.filter((events) => {
            events._id !== eventId;
          }),
        })),
      activeEvent: null,
      setActiveEvent: (newValue) => {
        return set({ activeEvent: newValue, activeShift: null });
      },

      eventDates: [],
      addEventDate: (newEventDate) =>
        set((state) => ({
          eventDates: state.eventDates.concat(newEventDate),
        })),
      removeEventDate: (eventDateId) =>
        set((state) => ({
          eventDates: state.eventDates.filter((eventDates) => {
            eventDates._id !== eventDateId;
          }),
        })),
      updateEventDate: async (newDate) => {
        console.log(newDate);
        try {
          const res = await fetch(`/api/dates/${newDate._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newDate),
          });

          console.log(res);
        } catch (e) {
          console.log(e);
        }

        return set((state: StoreType) => ({
          eventDates: state.eventDates.map((date) => {
            return date._id === newDate._id ? { ...newDate } : date;
          }),
        }));
      },

      shifts: [],
      addShift: async (formData) => {
        const res = await fetch(`/api/shifts`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            eventDateId: get().activeDate?._id,
            eventId: get().activeEvent?._id,
            shiftName: formData.shiftName,
            employeeAmount: formData.employeeAmount,
            shiftStart: formData.startHour,
            shiftEnd: formData.endHour,
            breakStart: formData.breakStart,
            breakEnd: formData.breakEnd,
            shiftInfo: formData.shiftInfo,
          }),
        });

        const newShift = await res.json();
        set((state) => ({
          shifts: [...state.shifts, newShift],
        }));
      },
      removeDate: async (dateId) => {
        try {
          const activeShifts = get().shifts.filter((shift) => {
            return shift.eventDateId === dateId;
          });

          if (activeShifts.length > 0) {
            return set({ loading: false });
          }

          const res = await fetch(`/api/dates/${dateId}`, {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              _id: dateId,
            }),
          });
          return set((state) => ({
            eventDates: state.eventDates.filter(
              (event) => event._id !== dateId
            ),
            loading: false,
          }));
        } catch (error) {
          console.log(error);
        }
      },
      removeShift: async (shiftId) => {
        const activeShiftAllocations = get().allocations.filter(
          (alloc) => alloc.shiftId === shiftId
        );
        if (activeShiftAllocations.length !== 0) {
          // @ts-ignore
          document.getElementById("deleteNotPossible")?.showModal();
          return set({ loading: false });
        }

        try {
          const res = await fetch(`/api/shifts/${shiftId}`, {
            method: "DELETE",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              _id: shiftId,
            }),
          });
          return set((state) => ({
            loading: false,
            activeShift: null,
            shifts: state.shifts.filter((shifts) => shifts._id !== shiftId),
          }));
        } catch (e) {
          console.log(e);
          return set({
            loading: false,
          });
        }
      },
      updateShiftInfo: async (shiftInfo) => {
        const { activeShiftForInfoModal } = get();
        const shiftId = activeShiftForInfoModal?._id;

        if (!shiftId) return;

        fetch(`/api/shifts/${shiftId}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id: shiftId,
            employeeAmount: activeShiftForInfoModal?.employeeAmount,
            shiftInfo: shiftInfo,
          }),
        });
        return set({
          shifts: get().shifts.map((shift) =>
            shift._id.toString() === shiftId ? { ...shift, shiftInfo } : shift
          ),
        });
      },
      setEmployeeAmount: async (updatedShift, employeeAmount) => {
        try {
          const res = await fetch(`/api/shifts/${updatedShift._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              employeeAmount,
              shiftInfo: updatedShift.shiftInfo,
            }),
          });

          // get().setActiveShift({ ...updatedShift, employeeAmount });

          //  console.log(res);
        } catch (e) {
          // console.log(e);
        }
        return set((state) => ({
          loading: false,
          shifts: state.shifts.map((shift) => {
            return shift._id === updatedShift._id
              ? { ...shift, employeeAmount }
              : shift;
          }),
        }));
      },

      activeDate: null,
      setActiveDate: (newValue) => {
        return set({ activeDate: newValue });
      },

      employees: [],
      bulkUploadErrors: [],
      addEmployee: async (newEmployee) => {
        try {
          const res = await fetch(`/api/employees`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: newEmployee.firstName,
              lastName: newEmployee.lastName,
              registerNumber: newEmployee.registerNumber,
              dateOfBirth: newEmployee.dateOfBirth,
              birthCity: newEmployee.birthCity,
              sex: newEmployee.sex,
              birthCountry: newEmployee.birthCountry,
              nationality: newEmployee.nationality,
              street: newEmployee.street,
              number: newEmployee.number,
              bus: newEmployee.bus,
              zip: newEmployee.zip,
              city: newEmployee.city,
              country: newEmployee.country,
              language: newEmployee.language,
              phone: newEmployee.phone,
              email: newEmployee.email,
              cevilRegistery: newEmployee.cevilRegistery,
              dependentSpouse: newEmployee.dependentSpouse,
              dependentChildren: newEmployee.dependentChildren,
              bankAccountNumber: newEmployee.bankAccountNumber,
              bic: newEmployee.bic,
              status: newEmployee.status,
              permanentEmployee: newEmployee.permanentEmployee,
              paymentInfo: newEmployee.paymentInfo,
            }),
          });

          const newEmployeeRes: IEmployee = await res.json();

          set((state) => ({
            employees: [...state.employees, newEmployeeRes],
            loading: false,
          }));
        } catch (e) {
          set({ loading: false });
          console.log(e);
        }
      },
      deleteAllEmployees: async () => {
        await fetch("/api/employees/bulk", {
          method: "DELETE",
        });
        set({
          employees: [],
          loading: false,
        });
      },
      bulkUpload: async (employees) => {
        try {
          const newEmployeesResponse = await fetch("/api/employees/bulk", {
            method: "POST",

            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(employees),
          });

          if (newEmployeesResponse.status === 422) {
            set((state) => ({
              loading: false,
              bulkUploadErrors: [],
            }));
            const errors = await newEmployeesResponse.json();
            console.log(errors.errorMessages);
            set((state) => ({
              loading: false,
              bulkUploadErrors: get().bulkUploadErrors.concat(
                errors.errorMessages
              ),
            }));
            throw new Error("Upload data error");
          }

          const newEmployees = await newEmployeesResponse.json();

          console.log("new emps", newEmployees);

          if (newEmployees.hasOwnProperty("errorMessages")) return;

          set((state) => ({
            loading: false,
            employees: newEmployees,
          }));

          router.push(routes.employees);
        } catch (e) {
          console.log(e);

          // @ts-ignore
          document.getElementById("bulkUploadError")?.showModal();
        }
      },
      removeEmployee: async (employeeId) => {
        try {
          const res = await fetch(`/api/employees/${employeeId}`, {
            method: "DELETE",
          });

          set((state) => ({
            employees: state.employees.filter(
              (employees) => employees._id !== employeeId
            ),
          }));
        } catch (e) {
          console.log(e);
        }
      },
      updateEmployee: async (updatedEmployee) => {
        try {
          const res = await fetch(`/api/employees/${updatedEmployee._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedEmployee),
          });

          console.log(res);
        } catch (e) {
          console.log(e);
        }
        return set((state: StoreType) => ({
          employees: state.employees.map((employee: IEmployee) => {
            return employee._id === updatedEmployee?._id
              ? { ...updatedEmployee }
              : employee;
          }),
        }));
      },

      allocations: [],
      addAllocation: async (allocation) => {
        try {
          const newAllocationRes = await fetch("/api/allocations", {
            method: "POST",

            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(allocation),
          });

          const allocationRes = await newAllocationRes.json();

          if (newAllocationRes.status === 409) {
            throw new Error("Employee already planned");
          }

          set((state) => ({
            loading: false,
            activeShift: null,
            allocations: state.allocations.concat(allocationRes),
          }));
        } catch (e) {
          //@ts-ignore
          if (e.message === "Employee already planned") {
            // @ts-ignore
            document.getElementById("alreadyPlanned")?.showModal();
            set({ loading: false });
          }
        }
      },

      removeAllocation: async (allocation) => {
        try {
          // check if the shift has a status of active else return and don't send the mail
          if (allocation.status === "available") {
            // send mail to the deleted user

            const shift = get().shifts.filter(
              (shift) => shift._id === allocation.shiftId
            )[0];

            const event = get().events.filter(
              (ev) => ev._id === allocation.eventId
            )[0];
            await get().sendEmail(
              //    allocation.employeeEmail,
              process.env.NEXT_PUBLIC_MOCK_EMAIL || allocation.employeeEmail,
              process.env.NEXT_PUBLIC_employeeRemovedFromAllocation!,
              formatDataForRemoveFromAllocation(allocation, shift, event)
            );
          }

          await fetch(`/api/allocations/${allocation._id}`, {
            method: "DELETE",
          });

          set((state) => ({
            loading: false,
            allocations: state.allocations.filter(
              (allocations) => allocations._id !== allocation._id
            ),
          }));
        } catch (e) {
          console.log(e);
        }
      },

      changeAllocationStatus: async (allocation, allocationStatus) => {
        try {
          get().setLoading(true);
          const res = await fetch(`/api/allocations/${allocation._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...allocation, status: allocationStatus }),
          });

          console.log(res);
          get().setLoading(false);
        } catch (e) {
          console.log(e);
          get().setLoading(false);
        }

        return set((state) => ({
          loading: false,
          allocations: state.allocations.map((alloc) => {
            return allocation._id === alloc._id
              ? { ...alloc, status: allocationStatus }
              : alloc;
          }),
        }));
      },

      showAllocateEmployeeAsside: false,
      setShowAllocatteEmployeeAsside: (newValue) => {
        return set({ showAllocateEmployeeAsside: newValue });
      },

      activeShift: null,
      setActiveShift: (newValue) => {
        return set({ activeShift: newValue });
      },

      sollicitations: [],
      addSollicitation: (sollicitation) =>
        set((state) => ({
          sollicitations: state.sollicitations.concat(sollicitation),
        })),

      inviteEmployees: async (date, employees) => {
        try {
          const resInvite = await fetch(`/api/sendInvites/${date?._id}`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              date: date,
              employees: employees,
            }),
          });

          if (resInvite.status === 500 || resInvite.status === 403) {
            throw new Error("Mail not send");
          }

          const dateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
          console.log(dateTime);

          const activeDate = get().activeDate;

          if (activeDate === undefined || activeDate === null) return;

          get().updateEventDate({
            ...activeDate,
            invitesSendAt: activeDate.invitesSendAt?.concat(dateTime),
          });

          get().setActiveDate({
            ...activeDate,
            invitesSendAt: activeDate.invitesSendAt?.concat(dateTime),
          });
        } catch (e) {
          console.log(e);

          // @ts-ignore
          document.getElementById("emailSendError")?.showModal();
        }
      },
      verifyEmployees: async (date) => {
        const {
          allocations,
          shifts,
          myCompany,
          employees,
          events,
          eventDates,
        } = get();
        const availableEmployees = allocations.filter(
          (alloc) => alloc.dateId === date._id && alloc.status === "pending"
        );

        const verifyData = availableEmployees.map((alloc) => {
          const selectedShift = shifts.find(
            (shift) => shift._id === alloc.shiftId
          );
          return {
            allocationId: alloc._id,
            organizer: myCompany,
            employeeId: alloc.employeeId,
            employeeEmail: alloc.employeeEmail,
            employeeName: alloc.employeeFirstName,
            employeeRegistry: employees.find((emp) => emp._id === alloc._id)
              ?.registerNumber,
            eventName: events.find((event) => event._id === alloc.eventId)
              ?.name,
            info: selectedShift?.shiftInfo,
            shiftName: shifts.find((shift) => shift._id === alloc.shiftId)
              ?.shiftName,
            shiftStart: shifts.find((shift) => shift._id === alloc.shiftId)
              ?.shiftStart,
            shiftEnd: selectedShift?.shiftEnd,
            breakStart: shifts.find((shift) => shift._id === alloc.shiftId)
              ?.breakStart,
            breakEnd: selectedShift?.breakEnd,
            date: eventDates.find(
              (date) => date._id === selectedShift?.eventDateId
            )?.eventDate,
          };
        });

        if (verifyData.length === 0) {
          return set({
            loading: false,
          });
        }

        try {
          const res = await fetch(`/api/sendVerification`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              verificationData: verifyData,
            }),
          });

          if (res.status === 500 || res.status === 403) {
            throw new Error("Mail not send");
          }

          const verifyRes = await res.json();

          // set status to verification sent
          set({
            loading: false,
            allocations: allocations.map((alloc) =>
              availableEmployees.map((a) => a._id).includes(alloc._id)
                ? { ...alloc, status: "verificationSent" }
                : alloc
            ),
          });
        } catch (e) {
          console.log(e);
          set({
            loading: false,
          });
          // @ts-ignore
          document.getElementById("emailSendError")?.showModal();
        }
      },

      activeTimeEmployee: null,
      setActiveTimeEmployee: (newValue: IEmployee) => {
        return set({ activeTimeEmployee: newValue });
      },

      worktimes: [],
      addWorktime: async (worktime: IWorkTime) => {
        try {
          get().setLoading(true);
          const res = await fetch(`/api/worktimes`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              shiftStart: worktime.shiftStart,
              shiftEnd: worktime.shiftEnd,
              pauseStart: worktime.pauseStart,
              pauseEnd: worktime.pauseEnd,
              employeeId: worktime.employeeId,
              allocationId: worktime.allocationId,
              note: worktime.note,
            }),
          });

          const newWorktimeRes: IWorkTime = await res.json();

          get().setLoading(false);

          return set((state) => ({
            worktimes: state.worktimes.concat(newWorktimeRes),
          }));
        } catch (e) {
          console.log(e);
        }
      },
      updateWorktime: async (newWorktime: IWorkTime) => {
        try {
          get().setLoading(true);
          const res = await fetch(`/api/worktimes/${newWorktime._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              shiftStart: newWorktime.shiftStart,
              shiftEnd: newWorktime.shiftEnd,
              pauseStart: newWorktime.pauseStart,
              pauseEnd: newWorktime.pauseEnd,
              employeeId: newWorktime.employeeId,
              allocationId: newWorktime.allocationId,
              note: newWorktime.note,
            }),
          });

          get().setLoading(false);
        } catch (e) {
          console.log(e);
        }
        return set((state: StoreType) => ({
          worktimes: state.worktimes.map((worktime) => {
            return worktime._id === newWorktime._id
              ? { ...newWorktime }
              : worktime;
          }),
        }));
      },
      updateWorktimeNote: async (noteValue: string, worktimeId: string) => {
        return set((state: StoreType) => ({
          worktimes: state.worktimes.map((worktime) => {
            return worktime._id === worktimeId
              ? { ...worktime, note: noteValue }
              : worktime;
          }),
        }));
      },
    })
  )
);

export default useStore;
